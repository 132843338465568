<template>
  <div class="start-page">
    <el-form :model="form" ref="form" label-width="80px" :rules="rules">
      <el-form-item label="启动图(中)">
        <el-upload
          action="https://www.ddpurse.com/uploadImage"
          list-type="picture-card"
          :limit="1"
          :file-list="zhStartPicList"
          :on-success="zhUploadSuccess"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="启动图(英)">
        <el-upload
          action="https://www.ddpurse.com/uploadImage"
          list-type="picture-card"
          :limit="1"
          :file-list="enStartPicList"
          :on-success="enUploadSuccess"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="点击效果" prop="action">
        <el-input
          v-model="form.action"
          type="string"
          placeholder="请输入跳转链接"
          :maxlength="100"
        ></el-input>
      </el-form-item>
      <el-form-item label="显示活动">
        <el-switch v-model="form.show_activity"></el-switch>
      </el-form-item>
      <el-form-item label="开始时间" prop="start_at">
        <el-date-picker
          v-model="form.start_at"
          type="datetime"
          value-format="timestamp"
          placeholder="选择日期时间"
        />
      </el-form-item>
      <el-form-item label="结束时间" prop="end_at">
        <el-date-picker
          v-model="form.end_at"
          type="datetime"
          value-format="timestamp"
          placeholder="选择日期时间"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">确认</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getStartConfig, setStartConfig } from "@/api/configs";
import { Message } from "element-ui";

export default {
  data() {
    return {
      form: {
        splash_img: "",
        splash_img_en: "",
        action: "",
        show_activity: false,
        start_at: "",
        end_at: "",
      },
      rules: {},
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapGetters(["startConfig"]),
    zhStartPicList() {
      if (this.form.splash_img) {
        return [
          {
            name: "zh_url",
            url: this.form.splash_img,
          },
        ];
      }
      return [];
    },
    enStartPicList() {
      if (this.form.splash_img_en) {
        return [
          {
            name: "en_url",
            url: this.form.splash_img_en,
          },
        ];
      }
      return [];
    },
  },
  methods: {
    fetchData() {
      let query = {};
      // this.$store.dispatch("configs/getStartConfig", query);
      getStartConfig(query).then((data) => {
        let json = data.data.data;
        json.start_at = +json.start_at * 1000;
        json.end_at = +json.end_at * 1000;
        this.form = json;
      });
    },
    zhUploadSuccess(data) {
      if (+data.code) {
        this.$message({
          message: "上传失败",
          duration: 3000,
          type: "waring",
        });
      } else {
        this.form.splash_img = data.data;
      }
    },
    enUploadSuccess(data) {
      if (+data.code) {
        this.$message({
          message: "上传失败",
          duration: 3000,
          type: "waring",
        });
      } else {
        this.form.splash_img_en = data.data;
      }
    },
    onSubmit() {
      let json = this.form;
      json.start_at = +json.start_at / 1000;
      json.end_at = +json.end_at / 1000;
      setStartConfig(json).then((data) => {
        if (+data.data.code) {
          this.$message({
            message: data.data.msg,
            duration: 3000,
            type: "error",
          });
          this.fetchData();
        } else {
          this.$message({
            message: data.data.msg,
            duration: 3000,
            type: "success",
          });
          this.fetchData();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.start-page {
  margin: 0;
  padding: 24px;

  .el-form {
    max-width: 360px;
  }
}
</style>
